import React from 'react';
import FormButton from '../../../components/registration/FormButton';

const FourOhFour = () => {
	return (
		<div className="center-child text-center" style={{ flexGrow: 1, flexDirection: 'column' }}>
			<div style={{ fontSize: 60, fontWeight: 900, color: 'var(--headline-1)' }}>404</div>
			<h1 style={{ color: 'var(--headline-2-3)' }}>Oops, This Page Could Not Be Found!</h1>
			<a href="https://waitro.org/" className="normalize-link">
				<FormButton disabled={false} text={'To WAITRO Website'} />
			</a>
		</div>
	);
};

export default FourOhFour;
