import { combineReducers } from 'redux';
import formReducer from './form.reducer';
import authReducer from './auth.reducer';
import redirectReducer from './redirect.reducer';
import interactionReducer from './interaction.reducer';

export default combineReducers({
	form: formReducer,
	auth: authReducer,
	redirect: redirectReducer,
	interaction: interactionReducer,
});
