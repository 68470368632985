/* eslint-disable import/no-anonymous-default-export */
import colors from './colors';

export default {
	primary: {
		main: colors.palette.purple,
	},
	secondary: {
		main: colors.palette.teal,
		// light: colors.palette.primaryPurple,
		// contrastText: colors.palette.primaryPurple,
	},
	error: {
		main: colors.text.error,
		// light: colors.accent.rubyRed2,
	},
	success: {
		main: colors.accent.viridianGreen1,
		light: colors.accent.viridianGreen2,
	},
	warning: {
		main: colors.accent.mustardYellow1,
		light: colors.accent.mustardYellow2,
	},

	textFieldLabel: {
		main: colors.text.headline2,
	},
	subText: {
		main: colors.text.gray,
	},
	errorText: {
		main: colors.text.error,
	},
};
