import React, { FunctionComponent, useEffect } from 'react';
import useStyles from '../../../helpers/materialStyles';
import FormButton from '../FormButton';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { interactionActions } from '../../../redux/actions/interaction.actions';
import { RootStateOrAny, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

type InteractionMessageProps = { role: string; headline_agree: string; description_agree: string[]; headline_disagree: string; description_disagree: string[] };
const InteractionMessage: FunctionComponent<InteractionMessageProps> = ({ role, headline_agree, description_agree = [], headline_disagree, description_disagree = [] }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const loading = useSelector((state: RootStateOrAny) => state.interaction.loading);
	const error = useSelector((state: RootStateOrAny) => state.interaction.error);
	const organization = useSelector((state: RootStateOrAny) => state.interaction.organization);
	let query = useQuery();
	const decision = query.get('decision');
	const token = query.get('token');
	let finalHeadline = decision === 'agree' || decision === 'approve' ? headline_agree : headline_disagree;
	const finalDescription = decision === 'agree' || decision === 'approve' ? description_agree : description_disagree;
	// if (role === 'admin') finalHeadline.replace('[organization]', organization);
	// const redirectToMainPage = () => {
	// 	window.location.href = 'https://waitro.org/';
	// };
	useEffect(() => {
		dispatch(interactionActions.registerResponse({ decision, token, role }));
	}, [decision, token]);
	if (loading) return <div className="page-center">{loading ? <CircularProgress size={25} color="secondary" /> : null}</div>;
	if (error)
		return (
			<div className="page-center">
				<div className={classes.formControl} style={{ textAlign: 'center' }}>
					<div className="headline1 margin-bottom-32">Sorry for the inconvenience.</div>
					<div className="margin-bottom-32">
						<div className={classes.formControl}>{error}</div>
					</div>
					<a href="https://waitro.org/" className="normalize-link">
						<FormButton disabled={false} text={'To WAITRO Website'} />
					</a>
				</div>
			</div>
		);
	return (
		<div className="page-center">
			<div className={classes.formControl} style={{ textAlign: 'center', maxWidth: 600 }}>
				<div className="headline1 margin-bottom-32">{finalHeadline}</div>
				<div>
					{finalDescription.map((item) => (
						<div className={classes.formControlMultiline}>{item}</div>
					))}
				</div>
				<a href="https://waitro.org/" className="normalize-link">
					<FormButton disabled={false} text={'To WAITRO Website'} />
				</a>
			</div>
		</div>
	);
};

export default InteractionMessage;
