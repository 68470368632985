import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Box, Container } from '@material-ui/core';
import './Footer.css';

export default function Footer() {
	return (
		<Container maxWidth="md">
			<Box className="footer" display="flex" flexDirection="column" alignItems="center">
				<Box display={{ sm: 'block', md: 'flex' }} width="100%" justifyContent="space-between" alignItems="center" marginBottom={1}>
					<a href="https://waitro.org/editorial-notes">EDITORIAL NOTES</a>
					<a href="https://waitro.org/privacy">DATA PROTECTION POLICY</a>
					<a href="https://waitro.org/terms-of-use">TERMS OF USE</a>
					<a href="https://waitro.org/contact">CONTACT</a>
					<Box className="footer-logos" display="flex">
						<a href="https://www.facebook.com/WAITRO">
							<FontAwesomeIcon icon={faFacebookF} />
						</a>
						<a href="https://www.linkedin.com/company/waitro">
							<FontAwesomeIcon icon={faLinkedinIn} />
						</a>
						<a href="https://twitter.com/WAITRO">
							<FontAwesomeIcon icon={faTwitter} />
						</a>
						<a href="https://www.instagram.com/waitro_org">
							<FontAwesomeIcon icon={faInstagram} />
						</a>
					</Box>
				</Box>
				<Box marginBottom={1}>©COPYRIGHT {new Date().getFullYear()} WAITRO - World Association of Industrial and Technological Research Organizations. All rights reserved.</Box>
			</Box>
		</Container>
	);
}
