/* eslint-disable import/no-anonymous-default-export */
export default {
	palette: {
		purple: '#ac0871',
		teal: '#00B8B0',
	},
	text: {
		main: '#000000',
		gray: '#9EA0A4',
		headline2: '#474C55',
		error: '#B80000',
	},

	accent: {
		mustardYellow1: '#F0CE12',
		mustardYellow2: '#FFF5BF',
		apricotOrange1: '#FFA92B',
		apricotOrange2: '#FFF6E9',
		rubyRed1: '#F64066',
		rubyRed2: '#FEEBEF',
		viridianGreen1: '#1CB69A',
		viridianGreen2: '#E7F8F5',
	},
	gray: {
		textGray: '#2E2E2E',
		textGray1: '#414141',
		textGray2: '#74798C',
		disableGray: '#C7C7C7',
		disableGray1: '#EAEAEA',
		disableGray2: '#F4F4F4',
		background: '#FDFDFF',
		darkGray: '#414141',
	},
};
