import * as types from '../constants/redirect.constants';

const redirect = (link) => ({ type: types.SET_REDIRECT_TO, payload: link });
const redirectThankyou = ({ link, token }) => ({ type: types.SET_REDIRECT_THANKYOU_TO, payload: { link, token } });
const removeRedirectTo = () => ({
	type: types.REMOVE_REDIRECT_TO,
	payload: null,
});

export const redirectActions = {
	redirect,
	redirectThankyou,
	removeRedirectTo,
};
