import React, { FunctionComponent } from 'react';
import useStyles from '../../../helpers/materialStyles';
import { useLocation } from 'react-router';
import FormButton from '../FormButton';
import Interweave from 'interweave';

type GenericMessageProps = { headline: string; description: string[] };
const GenericMessage: FunctionComponent<GenericMessageProps> = ({ headline, description }) => {
	const classes = useStyles();

	return (
		<div className="page-center">
			<div className={classes.formControl} style={{ textAlign: 'center', maxWidth: 600 }}>
				<div className="headline1  margin-bottom-32">{headline}</div>
				<div>
					{description.map((item) => (
						<div className={classes.formControlMultiline}>
							<Interweave content={item} />
						</div>
					))}
				</div>
				<a href="https://waitro.org/" className="normalize-link">
					<FormButton disabled={false} text={'To WAITRO Website'} />
				</a>
			</div>
		</div>
	);
};

export default GenericMessage;
