import React, { Suspense, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './redux/store';
import SystemRouter from './router/system';
import PublicLayout from './layouts/Public';
import theme from './themes/theme';
import './App.css';
import './normalize.css';
import { redirectActions } from './redux/actions/redirect.actions';
import { formActions } from './redux/actions/form.actions';
import { CircularProgress } from '@material-ui/core';
import GenericMessage from './components/registration/GenericMessage';

function App() {
	const redirect = useSelector((state) => state.redirect.redirectTo);
	const serverLoading = useSelector((state) => state.form.serverLoading);
	const serverReachable = useSelector((state) => state.form.serverReachable);
	const dispatch = useDispatch();
	const history = useHistory();
	useEffect(() => {
		if (redirect) {
			const temp = redirect;
			dispatch(redirectActions.removeRedirectTo());
			history.push(temp);
		}
	}, [redirect]);
	useEffect(() => {
		dispatch(formActions.pingServer());
	}, []);

	return (
		<div className="App">
			<ThemeProvider theme={theme}>
				{serverLoading ? (
					<div className="page-center">
						<div>
							<div>
								<CircularProgress size={50} color="secondary" />
							</div>
							Loading...
						</div>
					</div>
				) : !serverReachable ? (
					<GenericMessage
						headline="Technical issues at WAITRO"
						description={[
							'Sorry for the inconvience. We are facing technical issues.',
							'For more information, please get in touch with <a href="mailto:info@waitro.org">info@waitro.org</a>.',
						]}
					/>
				) : (
					<Suspense fallback={<div></div>}>
						{/* <Route path="/"> */}
						<PublicLayout>
							<Switch>
								{SystemRouter.filter(({ layout }) => layout === 'public').map(({ path, exact, Component, ...rest }) => (
									<Route key={path} exact={exact} path={path} {...rest} component={(props) => <Component {...props} {...props.match.params} {...rest} />} />
								))}
							</Switch>
						</PublicLayout>
						{/* </Route> */}
					</Suspense>
				)}
			</ThemeProvider>
		</div>
	);
}

export default App;
