import React, { FunctionComponent } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import { useHistory } from 'react-router-dom';
import FormButton from '../FormButton';
import useStyles from '../../../helpers/materialStyles';
import Interweave from 'interweave';
// import { RootStateOrAny, useSelector } from 'react-redux';

type ThankYouProps = { link?: string; width: number; buttonWidth?: number; buttonText: string; title: string; body: string[] };
const ThankYou: FunctionComponent<ThankYouProps> = ({ link, width, buttonWidth, buttonText = 'BACK TO WAITRO WEBSITE', title, body }) => {
	const classes = useStyles();
	// const history = useHistory();
	// const resend = useSelector((state: RootStateOrAny) => state.redirect.thankyouToken);
	const redirectToMainPage = () => {
		window.location.href = 'https://waitro.org/';
	};
	return (
		<div className="page-center">
			<div className={classes.formControl} style={{ maxWidth: width, textAlign: 'center' }}>
				<div className="headline1 margin-bottom-32">{title}</div>
				<div className="margin-bottom-32">
					{body.map((item, index) => (
						<div key={'text_' + index} className={classes.formControlMultiline}>
							<Interweave content={item} />
						</div>
					))}
				</div>
				<FormButton disabled={false} onClick={redirectToMainPage} text={buttonText} />
				{/* <div className="thank-you--small-text">
				You didn't receive an email? <a href={`something.com/${resend}`}>Resend confirmation email</a>
			</div> */}
			</div>
		</div>
	);
};

export default ThankYou;
