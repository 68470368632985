import * as types from '../constants/interaction.constants';
import api from '../../apiService';

const registerResponse =
	({ decision, token, role }) =>
	async (dispatch) => {
		try {
			if (!decision || !token || !role) throw new Error('Invalid link.');
			let url;
			switch (role) {
				case 'admin':
					if (decision === 'reject') url = `/organizations/register/reject/${token}`;
					else if (decision === 'approve') url = `/organizations/register/approve/${token}`;
					break;
				case 'contact-person':
					if (decision === 'disagree') url = `/organizations/register/contact-person/disagree/${token}`;
					if (decision === 'agree') url = `/organizations/register/contact-person/agree/${token}`;
					break;
				case 'deputy-contact-person':
					if (decision === 'disagree') url = `/organizations/register/deputy-contact-person/disagree/${token}`;
					if (decision === 'agree') url = `/organizations/register/deputy-contact-person/agree/${token}`;
					break;
				default:
					throw new Error('Invalid link.');
			}
			switch (decision) {
				case 'approve':
				case 'agree':
					{
						dispatch({ type: types.REGISTER_AGREE_REQUEST, payload: null });
						const res = await api.patch(url);
						dispatch({
							type: types.REGISTER_AGREE_SUCCESS,
							payload: res?.data?.message,
						});
					}
					break;
				case 'reject':
				case 'disagree':
					{
						dispatch({ type: types.REGISTER_DISAGREE_REQUEST, payload: null });
						const res = await api.delete(url);
						dispatch({
							type: types.REGISTER_DISAGREE_SUCCESS,
							payload: res?.data?.message,
						});
					}
					break;
				default:
					throw new Error('Invalid link.');
			}
		} catch (error) {
			dispatch({ type: types.REGISTER_DISAGREE_FAILURE, payload: error.message });
		}
	};

const checkAppointmentToken =
	({ token }) =>
	async (dispatch) => {
		try {
			dispatch({ type: types.APPOINTMENT_CHECK_REQUEST, payload: null });
			const res = await api.get(`/organizations/register/contact-person/appointment/${token}`);
			dispatch({
				type: types.APPOINTMENT_CHECK_SUCCESS,
				payload: res?.data?.message,
			});
		} catch (error) {
			dispatch({ type: types.APPOINTMENT_CHECK_FAILURE, payload: error.message });
		}
	};

export const interactionActions = {
	registerResponse,
	checkAppointmentToken,
};
