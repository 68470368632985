export const GET_PPOGRESSBAR_HEIGHT = 'GET_PPOGRESSBAR_HEIGHT';
export const SET_HEIGHT_CHANGE = 'SET_HEIGHT_CHANGE';

export const SUBMIT_APPLICATION_REQUEST = 'SUBMIT_APPLICATION_REQUEST';
export const SUBMIT_APPLICATION_SUCCESS = 'SUBMIT_APPLICATION_SUCCESS';
export const SUBMIT_APPLICATION_FAILURE = 'SUBMIT_APPLICATION_FAILURE';

export const CHECK_WEBSITE_REQUEST = 'CHECK_WEBSITE_REQUEST';
export const CHECK_WEBSITE_SUCCESS = 'CHECK_WEBSITE_SUCCESS';
export const CHECK_WEBSITE_FAILURE = 'CHECK_WEBSITE_FAILURE';

export const SAVE_APPLICATION_ITEM = 'SAVE_APPLICATION_ITEM';
export const SET_FORM_ERROR = 'SET_FORM_ERROR';
export const CLEAR_FORM = 'CLEAR_FORM';
export const SAVE_FORM_DATA = 'SAVE_FORM_DATA';

export const GET_ORGANIZATION_LIST_REQUEST = 'GET_ORGANIZATION_LIST_REQUEST';
export const GET_ORGANIZATION_LIST_SUCCESS = 'GET_ORGANIZATION_LIST_SUCCESS';
export const GET_ORGANIZATION_LIST_FAILURE = 'GET_ORGANIZATION_LIST_FAILURE';

export const GET_SERVER_RESPONSE_REQUEST = 'GET_SERVER_RESPONSE_REQUEST';
export const GET_SERVER_RESPONSE_SUCCESS = 'GET_SERVER_RESPONSE_SUCCESS';
export const GET_SERVER_RESPONSE_FAILURE = 'GET_SERVER_RESPONSE_FAILURE';

export const GET_REFERENCE_DATA_REQUEST = 'GET_REFERENCE_DATA_REQUEST';
export const GET_REFERENCE_DATA_SUCCESS = 'GET_REFERENCE_DATA_SUCCESS';
export const GET_REFERENCE_DATA_FAILURE = 'GET_REFERENCE_DATA_FAILURE';

export const CHECK_APPLICATION_FORM = 'CHECK_APPLICATION_FORM';
export const PASS_APPLICATION_FORM = 'PASS_APPLICATION_FORM';

export const SET_ERROR = '.FORM.SET_ERROR';
export const CLEAR_ERROR = '.FORM.CLEAR_ERROR';
