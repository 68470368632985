import { Button } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import useStyles from '../../../helpers/materialStyles';

type FormButtonProps = { type?: 'button' | 'submit' | 'reset' | undefined; disabled: boolean; onClick?: () => void; text: string };
const FormButton: FunctionComponent<FormButtonProps> = ({ disabled, onClick, text, type = 'button' }) => {
	const classes = useStyles();
	return (
		<div className={`center-child`}>
			<div className={classes.root}>
				<Button disabled={disabled} type={type} onClick={onClick} className="form-button standard-padding form-button--apply">
					{text}
				</Button>
			</div>
		</div>
	);
};

export default FormButton;
