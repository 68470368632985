export const REGISTER_AGREE_REQUEST = 'INTERACTION.REGISTER_AGREE_REQUEST';
export const REGISTER_AGREE_SUCCESS = 'INTERACTION.REGISTER_AGREE_SUCCESS';
export const REGISTER_AGREE_FAILURE = 'INTERACTION.REGISTER_AGREE_FAILURE';

export const REGISTER_DISAGREE_REQUEST = 'INTERACTION.REGISTER_DISAGREE_REQUEST';
export const REGISTER_DISAGREE_SUCCESS = 'INTERACTION.REGISTER_DISAGREE_SUCCESS';
export const REGISTER_DISAGREE_FAILURE = 'INTERACTION.REGISTER_DISAGREE_FAILURE';

export const APPOINTMENT_CHECK_REQUEST = 'INTERACTION.APPOINTMENT_CHECK_REQUEST';
export const APPOINTMENT_CHECK_SUCCESS = 'INTERACTION.APPOINTMENT_CHECK_SUCCESS';
export const APPOINTMENT_CHECK_FAILURE = 'INTERACTION.APPOINTMENT_CHECK_FAILURE';
