import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import style from './style';
import Footer from '../../components/Footer';

const PublicLayout = ({ classes, children, location }) => {
	return (
		<>
			{/* <PublicNavbar location={location} /> */}
			<div className={classes.body} style={{ height: '100%' }}>
				<div className={clsx(classes.content)} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
					{children}
					{location?.pathname !== '/' ? <Footer /> : null}
				</div>
			</div>
		</>
	);
};

PublicLayout.defaultProps = {};

PublicLayout.propTypes = {
	classes: PropTypes.shape(),
	location: PropTypes.shape(),
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default withStyles(style)(withRouter(PublicLayout));
