import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
	(theme) => ({
		formText: {
			marginTop: theme.spacing(1.1),
			// marginBottom: theme.spacing(1.1),
			fontSize: 14,
		},
		formTextarea: { fontSize: 14, marginTop: theme.spacing(1.1), marginBottom: theme.spacing(1.1), padding: 10 },

		subText_noMargin: { color: theme.palette.subText.main, fontSize: 14 },
		subText: {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
			color: theme.palette.subText.main,
			fontSize: 14,
		},

		formControl: {
			fontSize: 14,
			marginTop: theme.spacing(1.1),
			marginBottom: theme.spacing(1.1),

			'& label.Mui-focused': {
				color: theme.palette.secondary.main,
			},
			'& .MuiInput-underline:after': {
				borderBottomColor: theme.palette.secondary.main,
			},
			'& .MuiOutlinedInput-root': {
				'&.Mui-focused fieldset': {
					borderColor: theme.palette.secondary.main,
				},
			},
		},
		formControlMultiline: {
			fontSize: 14,
			marginTop: theme.spacing(0),
			// marginBottom: theme.spacing(1.1),
			lineHeight: '24px',

			'& label.Mui-focused': {
				color: theme.palette.secondary.main,
			},
			'& .MuiInput-underline:after': {
				borderBottomColor: theme.palette.secondary.main,
			},
			'& .MuiOutlinedInput-root': {
				'&.Mui-focused fieldset': {
					borderColor: theme.palette.secondary.main,
				},
			},
		},
		loginControl: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),

			'& label.Mui-focused': {
				color: theme.palette.primary.main,
			},
			'& .MuiInput-underline:after': {
				borderBottomColor: theme.palette.primary.main,
			},
			// '& .MuiOutlinedInput-root': {
			// 	'&.Mui-focused fieldset': {
			// 		borderColor: theme.palette.secondary.main,
			// 	},
			// },
		},
		formControlError: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),

			'& label.Mui-focused': {
				color: 'var(--headline-1)',
			},
			'& .MuiInput-underline:after': {
				borderBottomColor: theme.palette.secondary.main,
			},
			'& .MuiOutlinedInput-root': {
				'& fieldset': {
					borderColor: theme.palette.secondary.main,
					borderWidth: '2px',
				},
				'&.Mui-focused fieldset': {
					borderColor: theme.palette.secondary.main,
				},

				'&:hover fieldset': {
					borderColor: theme.palette.secondary.main,
					borderWidth: '2px',
				},
			},
		},

		selectEmpty: {
			marginTop: theme.spacing(1),
		},
		menuPaper: {
			maxHeight: 150,
		},
		root: {
			'& > *': {
				margin: theme.spacing(1),
			},
		},
		input: {
			display: 'none',
		},
		icon: {
			right: '60px',
		},
		sectionTitle: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
			letterSpacing: ' var(--character-spacing-0)',
			color: theme.palette.primary.main,
			textAlign: 'center',
			font: 'normal normal normal var(--font-size-19) Lato',
			textTransform: 'uppercase',
		},
		formGrid: {
			marginTop: 0,
			marginBottom: 0,

			'& .MuiGrid-item': {
				paddingTop: 0,
				paddingBottom: 0,
			},
		},
		error: {
			fontSize: 14,
			color: theme.palette.errorText.main,
		},
		labelWithStartAdornment: {
			transform: 'translate(38px, 12px) scale(1)!important',
		},
		labelWithStartAdornmentShrink: {
			transform: 'translate(14px, -6px) scale(0.75)!important',
		},
	}),
	{ index: 1 }
);

export default useStyles;
