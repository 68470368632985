import * as types from '../constants/form.constants';
import { redirectActions } from './redirect.actions';
import api from '../../apiService';

const getProgressBarHeight = (height) => (dispatch) => {
	dispatch({ type: types.GET_PPOGRESSBAR_HEIGHT, payload: height });
};

const setHeightChange = () => (dispatch) => {
	dispatch({ type: types.SET_HEIGHT_CHANGE });
};
const saveApplicationItem = (item, parent) => (dispatch) => {
	dispatch({ type: types.SAVE_APPLICATION_ITEM, payload: { item, parent } });
};
const saveFormData = (item, parent) => (dispatch) => {
	dispatch({ type: types.SAVE_FORM_DATA, payload: { item, parent } });
};

// const clearForm = () => (dispatch) => {
// 	dispatch({ type: types.CLEAR_FORM, payload: null });
// };
const checkApplicationForm = () => (dispatch) => {
	dispatch({ type: types.CHECK_APPLICATION_FORM, payload: null });
};
const submitApplication = (form, originalPath) => async (dispatch) => {
	dispatch({ type: types.SUBMIT_APPLICATION_REQUEST, payload: null });
	try {
		// post form to backend and get a resend email token
		const res = await api.post('/organizations/register', form);
		if (res.status === 201) {
			dispatch({
				type: types.SUBMIT_APPLICATION_SUCCESS,
				payload: null,
			});
			dispatch(redirectActions.redirectThankyou({ link: `/thank-you`, token: res.data.token }));
		} else dispatch({ type: types.SUBMIT_APPLICATION_FAILURE, payload: res.statusText });
	} catch (error) {
		dispatch({ type: types.SUBMIT_APPLICATION_FAILURE, payload: error.message });
	}
};
const submitAppointment = (form, token, originalPath) => async (dispatch) => {
	dispatch({ type: types.SUBMIT_APPLICATION_REQUEST, payload: null });
	try {
		// post form to backend and get a resend email token
		const res = await api.post(`/organizations/register/contact-person/appointment/${token}`, form);
		if (res.status === 201) {
			dispatch({
				type: types.SUBMIT_APPLICATION_SUCCESS,
				payload: null,
			});
			dispatch(redirectActions.redirectThankyou({ link: `${originalPath}/thank-you` }));
		} else dispatch({ type: types.SUBMIT_APPLICATION_FAILURE, payload: res.statusText });
	} catch (error) {
		dispatch({ type: types.SUBMIT_APPLICATION_FAILURE, payload: error.message });
	}
};

const setFormError = (item, parent) => (dispatch) => {
	dispatch({ type: types.SET_FORM_ERROR, payload: { item, parent } });
};
const getOrganizationList = () => async (dispatch) => {
	dispatch({ type: types.GET_ORGANIZATION_LIST_REQUEST, payload: null });
	try {
		const res = await api.get('organizations');
		dispatch({
			type: types.GET_ORGANIZATION_LIST_SUCCESS,

			payload: res.data,
		});
	} catch (error) {
		dispatch({ type: types.GET_ORGANIZATION_LIST_FAILURE, payload: error });
	}
};

const getReferenceData = () => async (dispatch) => {
	dispatch({ type: types.GET_REFERENCE_DATA_REQUEST, payload: null });
	try {
		// get data from api here
		const res = await api.get('/reference-data');
		dispatch({
			type: types.GET_REFERENCE_DATA_SUCCESS,
			payload: res.data,
		});
	} catch (error) {
		dispatch({ type: types.GET_REFERENCE_DATA_FAILURE, payload: error });
	}
};

const setError = (error) => async (dispatch) => {
	dispatch({ type: types.SET_ERROR, payload: error });
};
const clearError = (error) => async (dispatch) => {
	dispatch({ type: types.CLEAR_ERROR, payload: error });
};

const checkWebsite = (website) => async (dispatch) => {
	dispatch({ type: types.CHECK_WEBSITE_REQUEST, payload: null });
	try {
		// get data from api here
		const res = await api.get(`/organizations/findByWebsite?website=${website}`);
		dispatch({
			type: types.CHECK_WEBSITE_SUCCESS,
			payload: res.data,
		});
		return res;
	} catch (error) {
		dispatch({ type: types.CHECK_WEBSITE_FAILURE, payload: error });
		return { error: true };
	}
};

const pingServer = () => async (dispatch) => {
	dispatch({ type: types.GET_SERVER_RESPONSE_REQUEST, payload: null });
	try {
		// get data from api here
		const res = await api.get(`/ping`);
		dispatch({
			type: types.GET_SERVER_RESPONSE_SUCCESS,
			payload: res.data,
		});
		return res;
	} catch (error) {
		console.error('server is not reachable')
		dispatch({
			type: types.GET_SERVER_RESPONSE_SUCCESS,
			payload: true,
		});

		return;
		// dispatch({ type: types.GET_SERVER_RESPONSE_FAILURE, payload: error });
		// return { error: true };
	}
};

export const formActions = {
	submitApplication,
	getProgressBarHeight,
	setHeightChange,
	getOrganizationList,
	checkApplicationForm,
	setFormError,
	getReferenceData,
	saveApplicationItem,
	saveFormData,
	submitAppointment,
	setError,
	clearError,
	checkWebsite,
	pingServer,
};
