import * as types from '../constants/form.constants';

const initialState = {
	referenceData: {},
	form: { hasDeputy: false, sameAddress: false, acceptTerms: false, formData: {} },
	loading: true,
	loadingStates: {
		organizations: true,
		reference_data: true,
	},
	formError: { reCaptcha: true },
	details: {},
	progressBarHeight: 0,
	heightChange: 0,
	error: null,
	checkWebsiteLoading: false,
	triggerCheck: false,
	formFilled: false,
	serverLoading: true,
	serverReachable: false,
};

const formReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case types.GET_SERVER_RESPONSE_REQUEST:
			return { ...state, serverLoading: true };
		case types.GET_SERVER_RESPONSE_SUCCESS:
			return { ...state, serverReachable: true, serverLoading: false };
		case types.CHECK_WEBSITE_REQUEST:
			return { ...state, checkWebsiteLoading: true };
		case types.CHECK_WEBSITE_SUCCESS:
		case types.CHECK_WEBSITE_FAILURE:
			return { ...state, checkWebsiteLoading: false };
		case types.CLEAR_FORM:
			return { ...state, form: { hasDeputy: false, sameAddress: false, acceptTerms: false, formData: {} } };
		case types.SET_FORM_ERROR:
			return { ...state, formError: { ...state.formError, [payload.parent]: payload.item } };
		case types.SAVE_FORM_DATA:
			return { ...state, form: { ...state.form, formData: { ...state.form.formData, [payload.parent]: payload.item } } };
		case types.SAVE_APPLICATION_ITEM:
			return { ...state, form: { ...state.form, [payload.parent]: payload.item } };
		case types.GET_REFERENCE_DATA_SUCCESS:
			return { ...state, loading: false, referenceData: { ...state.referenceData, ...payload } };
		case types.CHECK_APPLICATION_FORM:
			return { ...state, triggerCheck: true };
		// case types.PASS_APPLICATION_FORM:
		// 	return { ...state, triggerCheck: false, formFilled: true };
		case types.SET_HEIGHT_CHANGE:
			return { ...state, heightChange: state.heightChange + 1 };
		case types.GET_PPOGRESSBAR_HEIGHT:
			return { ...state, progressBarHeight: payload };

		case types.SUBMIT_APPLICATION_REQUEST:
			return { ...state, loading: true, error: null };
		case types.GET_REFERENCE_DATA_REQUEST:
			return { ...state, loading: true };
		case types.GET_SERVER_RESPONSE_FAILURE:
			return { ...state, serverLoading: false };
		case types.GET_REFERENCE_DATA_FAILURE:
			return { ...state, loading: false };
		case types.GET_ORGANIZATION_LIST_REQUEST:
			return { ...state, loadingStates: { ...state.loadingStates, organizations: true } };
		case types.GET_ORGANIZATION_LIST_SUCCESS:
			return { ...state, loadingStates: { ...state.loadingStates, organizations: false }, referenceData: { ...state.referenceData, organizations: payload } };
		case types.GET_ORGANIZATION_LIST_FAILURE:
			return { ...state, loadingStates: { ...state.loadingStates, organizations: false }, error: payload };
		case types.SUBMIT_APPLICATION_FAILURE:
		case types.SET_ERROR:
			return { ...state, error: payload, loading: false };
		case types.CLEAR_ERROR:
			return { ...state, error: null };
		default:
			return state;
	}
};

export default formReducer;
