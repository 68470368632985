import React from 'react';
import InteractionMessage from '../components/registration/InterractionMessage';
import ThankYou from '../components/registration/ThankYou';
import FourOhFour from '../pages/registration/FourOhFour';
const HomePage = React.lazy(() => import('../pages/registration/HomePage'));
const OrganizationApplication = React.lazy(() => import('../pages/registration/OrganizationApplication'));
// const IndividualRegistration = React.lazy(() => import('../pages/registration/IndividualRegistration'));
const Onboarding = React.lazy(() => import('../pages/registration/Onboarding'));
// const Login = React.lazy(() => import('../pages/registration/Login'));
const OrganizationThankYou = React.lazy(() => import('../pages/registration/OrganizationThankYou'));
// const IndividualThankYou = React.lazy(() => import('../pages/registration/IndividualThankYou'));

const SystemRouter = [
	{
		path: '/',
		Component: HomePage,
		exact: true,
		layout: 'public',
	},
	{
		path: '/full-member-apply',
		Component: OrganizationApplication,
		type: 'Full Member',
		exact: true,
		layout: 'public',
	},
	{
		path: '/associate-member-apply',
		Component: OrganizationApplication,
		exact: true,
		type: 'Associate Member',
		layout: 'public',
	},
	{
		path: '/thank-you',
		Component: OrganizationThankYou,
		exact: true,
		layout: 'public',
	},
	// {
	// 	path: '/register',
	// 	Component: IndividualRegistration,
	// 	exact: true,
	// 	layout: 'public',
	// },
	// {
	// 	path: '/register/thank-you',
	// 	Component: IndividualThankYou,
	// 	exact: true,
	// 	layout: 'public',
	// },
	{
		path: '/appointment',
		Component: Onboarding,
		exact: true,
		layout: 'public',
	},
	{
		path: '/appointment/thank-you',
		Component: ThankYou,
		title: 'Thank you for getting in touch with us.',
		body: ['We will shortly process your request'],
		exact: true,
		layout: 'public',
	},

	// {
	// 	path: '/login',
	// 	Component: Login,
	// 	exact: true,
	// 	layout: 'public',
	// },

	{
		path: '/cp-nomination',
		Component: InteractionMessage,
		exact: true,
		layout: 'public',
		role: 'contact-person',
		headline_agree: 'Thank you for agreeing to be the contact person for your organization at WAITRO',
		description_agree: ['Next steps:', '1. The WAITRO	Secretary will review your organization and approve it.', '2. Once approved, you will receive a welcome letter from WAITRO.'],
		headline_disagree: 'You have declined the application of your organization at WAITRO.',
		description_disagree: ['The organization and all its information will be immediately deleted from the WAITRO system.'],
	},
	{
		path: '/dcp-nomination',
		Component: InteractionMessage,
		exact: true,
		layout: 'public',
		role: 'deputy-contact-person',
		headline_agree: 'You have accepted your nomination!',
		description_agree: [
			`We are happy to welcome you as your organization’s WAITRO Deputy Contact Person. Please coordinate yourself with your organization’s WAITRO Contact Person. The WAITRO Secretariat will be in contact shortly for an onboarding meeting.`,
		],
		headline_disagree: 'You have rejected your nomination!',
		description_disagree: [
			'Unfortunately, you have rejected your nomination to be your organization’s WAITRO Deputy Contact Person. All data related to your nomination as Deputy Contact Person has been deleted.',
		],
	},
	{
		path: '/admin',
		Component: InteractionMessage,
		exact: true,
		layout: 'public',
		role: 'admin',
		headline_agree: 'You have approved the application of the organization to WAITRO.',
		description_agree: [`A welcome letter will be sent to the contact person now and an confirmation email to the deputy contact person.`],
		headline_disagree: 'You have rejected the application of the organization to WAITRO.',
		description_disagree: ['The organization and all its information will be immediately deleted from the WAITRO system.'],
	},
	{
		path: '*',
		Component: FourOhFour,
		layout: 'public',
	},
];

export default SystemRouter;
