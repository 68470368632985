import * as types from '../constants/interaction.constants';

const initialState = {
	loading: true,
	error: null,
	organization: null,
};

const interactionReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case types.REGISTER_AGREE_REQUEST:
		case types.REGISTER_DISAGREE_REQUEST:
		case types.APPOINTMENT_CHECK_REQUEST:
			return { ...state, loading: true };
		case types.REGISTER_AGREE_SUCCESS:
		case types.REGISTER_DISAGREE_SUCCESS:
		case types.APPOINTMENT_CHECK_SUCCESS:
			return { ...state, organization: payload, loading: false };
		case types.REGISTER_AGREE_FAILURE:
		case types.REGISTER_DISAGREE_FAILURE:
		case types.APPOINTMENT_CHECK_FAILURE:
			return { ...state, loading: false, error: payload };
		default:
			return state;
	}
};

export default interactionReducer;
