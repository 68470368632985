/* eslint-disable import/no-anonymous-default-export */
import palette from './palette';

export default {
	MuiFormLabel: {
		root: {
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			right: '22px',
			color: palette.textFieldLabel.main,
		},
	},
	MuiOutlinedInput: {
		root: {
			fontSize: '14px',
			fontFamily: 'Lato',
			background: '#fff',
		},
		input: {
			height: 38,
			boxSizing: 'border-box',
		},
	},
	MuiInputLabel: {
		root: { fontFamily: 'Lato', fontSize: '14px' },
		outlined: {
			transform: 'translate(14px, 12px) scale(1)',
		},

		shrink: {
			right: 'unset',
		},
	},

	MuiFormHelperText: {
		root: {
			color: palette.errorText.main + '!important',
			fontFamily: 'Lato',
			fontSize: '12px',
			// height: 0,
			marginBottom: '-19px!important',
			letterSpacing: 0,
		},
	},
	MuiAutocomplete: {
		inputRoot: {
			fontSize: '14px',
			minHeight: 38,
		},
		input: {
			height: 26,
		},
	},
	MuiChip: {
		root: {
			backgroundColor: '#E4ECED',
		},
		label: {
			fontSize: '11px',
		},
		deleteIconSmall: {
			width: '13px',
			height: '13px',
		},
	},
};
