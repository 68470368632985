import axios from 'axios';
const CONSOLE_LOG = process.env.REACT_APP_CONSOLE_LOG;

const api = axios.create({
	baseURL: process.env.REACT_APP_BACK_END,
	timeout: 5000,
	headers: {
		'Content-Type': 'application/json',
	},
});
/**
 * console.log all requests and responses
 */
api.interceptors.request.use(
	(request) => {
		if (CONSOLE_LOG) {
			console.log('Starting Request', request);
		}
		return request;
	},
	function (error) {
		if (CONSOLE_LOG) {
			console.log('REQUEST ERROR', error);
		}
	}
);

api.interceptors.response.use(
	(response) => {
		if (CONSOLE_LOG) {
			console.log('Response:', response);
		}
		if (response.data.data && response.data.data.accessToken) {
			api.defaults.headers.common['authorization'] = 'Bearer ' + response.data.data.accessToken;
		}
		return response;
	},
	function (error) {
		error = error.response.data;
		if (CONSOLE_LOG) {
			console.log('RESPONSE ERROR', error);
		}
		let errorMsg = error.message || '';
		if (error.errors && error.errors.message) errorMsg = errorMsg + ': ' + error.errors.message;
		return Promise.reject(error);
	}
);

export default api;
